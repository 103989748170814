import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard/home', title: 'Home', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: '/dashboard/dominios', title: 'Domínios', icon: 'ft-loader', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/dominios', title: 'Vista Geral', icon: 'ft-loader', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/dominios/investigadores', title: 'Investigadores', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/dashboard/projetos', title: 'Projetos', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/projetos', title: 'Pesquisa', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/criarProjeto', title: 'Criar Projeto', icon: 'fas fa-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/meusProjetosControlados', title: 'Listagens', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/meusProjetos', title: 'Meus', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/meuCentroProjetos', title: 'Meu Centro', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/reports/projectContactReport', title: 'Rel. Acompanhamento', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/timecards', title: 'Proc TC-PO', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/timecardsManagement', title: 'Time cards', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/timecardsCompl', title: 'Proc Compl.', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/tipologias', title: 'Tipologias', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/subvInvm', title: 'Medidas Subvenção', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/prestacoesServico', title: 'Prestações Serviço', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/projetos/interoperabilidade', title: 'Interoperabilidade', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/dashboard/candidaturas', title: 'Candidaturas', icon: 'icon-graduation', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/candidaturas/estagios/totalcandidaturas', title: 'Estágios', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/dashboard/bolsas-propinas/bolsas/entidadesFinanciadoras/pesquisa-entidades-financiadoras', title: 'Entidades Financiadoras de Bolsas', icon: 'icon-graduation', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/candidaturas/doutoramentos/totalcandidaturas', title: 'Doutoramentos', icon: 'icon-graduation', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    path: '/dashboard/entidades', title: 'Entidades', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/entidades/pesquisa', title: 'Pesquisa', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/entidades/entidade/create', title: 'Criar', icon: 'ft-edit', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '/dashboard/publicacoes', title: 'Publicações', icon: 'ft-file-text', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/pesquisaPublicacoes', title: 'Pesquisa', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/minhasPublicacoes', title: 'Minhas', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/meuCentroPublicacoes', title: 'Meu Centro', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/quadroResumo', title: 'Quadro Resumo', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/uploadPublicacoes', title: 'Upload', icon: 'ft-upload', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/citacoesPublicacoes', title: 'Exportar', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  {
    path: '/dashboard/tesesdissertacoes', title: 'Teses', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/tesesdissertacoesList', title: 'Pesquisa', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/addtesesdissertacoes', title: 'Adicionar', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  {
    path: '/dashboard/recursosHumanos-', title: 'Recursos Humanos', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/fichaPessoal', title: 'Ficha Pessoal', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/dkendoui', title: 'Kendo', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/Shares', title: 'Partilhas', icon: 'ft-share-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/Steering', title: 'Steering', icon: 'ft-share-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
	    { path: '/dashboard/pesquisaColaborador', title: 'Pesquisa Colaborador', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagemsriestrangeiros', title: 'ListagemSRI', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/historico', title: 'Gestão Histórico', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/historicoColaboradorEstrutura', title: 'Histórico', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/procCGRH', title: 'Proc CF-RH', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/procTCPO', title: 'Proc TC-PO', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/procCB', title: 'Proc Compl. Bolsa', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/procCD', title: 'Proc Compl. Docentes', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/rhRegistoFEUP', title: 'Registo FEUP', icon: 'icon-graduation', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/trlPessoal', title: 'TRL Pessoal', icon: 'ft-thermometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagensrhglobal', title: 'Listagem Global', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagensrhsintese', title: 'Listagem Síntese', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagensrhRemunBolsEstag', title: 'Listagem Remun Bols Estag', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagensrhRemunContr', title: 'Listagem Remun Contr', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagensrhperfisfuncionais', title: 'Listagem Perfis Funcionais', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagensrhBolsContrMedTrabalho', title: 'Listagem Bol Con Med Tra', icon: 'ft-align-justify', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/sintese1', title: 'Sintese 1', icon: 'ft-thermometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/sintese2', title: 'Sintese 2', icon: 'ft-thermometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/sintese3', title: 'Sintese 3', icon: 'ft-thermometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/sintese4', title: 'Sintese 4', icon: 'ft-thermometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/sintese5', title: 'Sintese 5', icon: 'ft-thermometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/recursos-humanos/research_line_colaborador/research_line_listagem', title: 'Linhas de Investigação', icon: 'icon-graduation', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/ficha-pessoal/acumulacaoDocencia/validarInstituicao/pesquisa-Instituicao', title: 'Validar Instituição', icon: 'icon-graduation', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/dashboard/ficha-pessoal/acumulacaoDocencia/pedidoAcumulacaoDocencia/adicionar-acumulacaoDocencia', title: 'Acumulação Docência', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/ficha-pessoal/acumulacaoDocencia/pedidoAcumulacaoDocencia/pesquisa-acumulacaoDocencia', title: 'Acumulação Docência', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/equipaFCT', title: 'Equipa FCT', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/narrativeCV', title: 'Narrative CV', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/dashboard/recursos-humanos/editaisestagios/lista', title: 'Editais', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/dashboard/recursos-humanos/editaisestagios/seleccao', title: 'Seleção editais', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  {
    path: '/dashboard/bolsas-propinas', title: 'Bolsas Propinas', icon: 'icon-graduation', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/bolsas-propinas/propinas/controleDePropinas/pesquisa-configuracao-propina', title: 'Configuração de Propinas', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/dashboard/bolsas-propinas/bolsas/entidadesFinanciadoras/pesquisa-entidades-financiadoras', title: 'Entidades Financiadoras de Bolsas', icon: 'icon-graduation', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/bolsas-propinas/bolsas/controleDeBolsas/pesquisa-bolsas', title: 'Configuração de Bolsas', icon: 'icon-graduation', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/bolsas-propinas/estudantes/inscricaoCurso/pesquisa-inscricao-curso', title: 'Inscrição Curso / Ano', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/bolsas-propinas/propinas/validacaoItensPropinas/validacao-itens-propinas', title: 'Validação Itens Propinas', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/bolsas-propinas/propinas/reembolsoPropinas/reembolso-propinas', title: 'Reembolso Propinas', icon: 'ft-sliders', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/bolsas-propinas/propinas/ficheiroPropinas/gerar-ficheiro-propinas', title: 'Gerar Ficheiro Propinas', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      //{ path: '/dashboard/bolsas-propinas/propinas/ficheiroPropinas/gerar-ficheiro-propinas', title: 'Importar Ficheiro Propinas', icon: 'ft-upload', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  {
    path: '/dashboard/conflitoInteresses', title: 'Conflito Interesses', icon: 'ft-alert-octagon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/conflitoInteresseslistaeer', title: 'Lista EER', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/conflitoInteresseslistaeercolaboradores', title: 'Lista Colabor PGCI', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/conflitoInteresseslistaeercgci', title: 'Lista EER Completa', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/conflitoInteresseshistorico', title: 'Histórico CGCI', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  {
    path: '/dashboard/indicadores', title: 'Indicadores', icon: 'ft-trending-up', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/indicadores-centros', title: 'Indic Atividade', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/recursosHumanos', title: 'RH Novos/Ativos', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/pesquisaIndicadores', title: 'Indic. Estratégicos', icon: 'ft-trending-up', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/relatorio_indicadores_listagem', title: 'RH Trimestrais/Anuais', icon: 'ft-thermometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '/dashboard/equipamento', title: 'NetDB', icon: 'ft-server', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/dashboard/pesquisarEquipamentos', title: 'Pesquisa Equipamentos', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/searchBuildings', title: 'Pesquisa Plantas', icon: 'far fa-map', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/addEquipamento', title: 'Adicionar Equipamento', icon: 'fas fa-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/listagemAlteracoes', title: 'Gestão Alterações', icon: 'fas fa-clipboard-check', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/dashboard/pesquisaEdificio', title: 'Pesquisa Edifício', icon: 'fas fa-building', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

];
